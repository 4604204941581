import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'angular-crumbs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
//breadcrumbs: MenuItem[];
export class ShellComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
     // this.breadcrumbs = crumbs.map(c => this.toPrimeNgMenuItem(c));
  });
  }
  
  //private toPrimeNgMenuItem(crumb: Breadcrumb) {
  //  return <MenuItem>{ label: crumb.displayName, url: `#${crumb.url}`}
//}

}
