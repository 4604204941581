import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as SecureStorage from 'secure-web-storage';
import { AppConfig } from './app.config';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  secureStorage:any;
  constructor() {
    this.Storage();
  }
  Storage(){
    var secret_Key = AppConfig.settings.keys.secretKey;
    this.secureStorage = new SecureStorage(localStorage, {

      // Encrypt the localstorage data
      encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, secret_Key);
        data = data.toString();
        return data;
      },
      // Decrypt the encrypted data
      decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, secret_Key);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      },
      hash: function hash(key) {
        key = CryptoJS.SHA256(key, secret_Key);
        return key.toString();
    }
    });
  }
 
  // set to localstarge  
  setJsonValue(key: string, value: any) {
    this.secureStorage.setItem(key, value);
  }
  // get value form localstarge
  getJsonValue(key: string) {
    return this.secureStorage.getItem(key);
  }
  // clear localstarge
  clear() {
    return this.secureStorage.clear();
  }
  // remove item form localstarage
  remove(key) {
    this.secureStorage.removeItem(key);
  }
}