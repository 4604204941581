import { Component, OnInit, Inject } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/language.service';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { HomeService } from '../../home.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { OrderCoinView } from 'src/app/models_view/order_coin_view';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Order } from 'src/app/models/order';

export interface DialogData2 {
  url: string;
}

@Component({
  selector: 'app-order',
  templateUrl: './orderDetails.component.html',
  styleUrls: ['./orderDetails.component.scss']
})

export class OrderDetailsComponent implements OnInit {
  order_list: OrderCoinView[];
  id: number;
  order: Order;
  number: number;
  personId: number;
  check: string;
  constructor(public dialog: MatDialog, private _sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute, public router: Router, public homeService: HomeService, private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private translate: TranslateService, private langService: LanguageService, private TitleService: Title) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      translate.setDefaultLang(this.langService.getLang().toLowerCase());
      this.translate.use(this.langService.getLang().toLowerCase());
      this.getMyOrders();
      this.getOrderCheck();
    });

  }
  ngOnInit() {
    this.getOrderById(this.id);
    this.translate.use(this.langService.getLang().toLowerCase());

    this.personId = this.homeService.get_userId_fromToken();
    
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent2, {
      width: '1000px',
      data: { url: this.check },
      panelClass: "no-scroll",
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  getImageBase64(data: any) {

    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + data);
  }

  getMyOrders() {
    this.homeService.getOrderCoins(this.id).subscribe(
      ((data: OrderCoinView[]) => {
        this.order_list = data;
      }));
  }

  getOrderById(id: number) {
    this.homeService.getOrderById(id).subscribe(
      ((data: Order) => {
        this.order = data;
        this.number = this.order.orderNumber;        
        if (this.order.personId != this.personId || this.order.orderStatusId == 3) {
           this.router.navigate(['/']);
        }
      }));
  }

  getOrderCheck() {
    this.homeService.getOrderCheck(this.id).subscribe(
      ((data: string) => {
        this.check = data;
      }),
      err => {
        if(err.status === 401)
        {
          this.router.navigate(['/']);
        }
      });
  }
  setTitle(title: string) {
    this.TitleService.setTitle(title + ' | E-CBAR.AZ');
    if (this.langService.getLang() == 'az') {
      title = 'Sifariş - ' + this.number;
      var order = "Sifarişlərim";

    } else if (this.langService.getLang() == 'en') {
      title = 'Order - ' + this.number;
      var order = "My Orders";
    }
    const breadcrumb = { Title: title, Order: order };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);

  }

  is_loggin() {
    if (localStorage.getItem("coin_token") == null) {
      return false;
    }
    else {
      return true;
    }
  }
}


@Component({
  selector: 'dialog2-component',
  templateUrl: 'dialog2-component.component.html',
  styleUrls: ['./orderDetails.component.scss']
})

export class DialogComponent2 {
  data_pdf: any;
  data_source: any;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent2>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2, private _sanitizer: DomSanitizer) {
    this.decodeReceipt(data.url);
    this.data_pdf = this.getHtml(data.url);
  }

  public convetToPDF() {
    document.getElementById('x').innerHTML = this.data_pdf;
    var p_content = document.getElementById('x');
    html2canvas(p_content, { scale: 2 }).then(canvas => {
      var imgWidth = 208;
      var imgHeight = 55;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 7;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('paymentReceiptContent.pdf');
    });
    document.getElementById('x').innerHTML = null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  decodeReceipt(data: string) {

    data = decodeURIComponent(escape(window.atob(data.substring(1, data.length - 1))));
    this.data_source = this._sanitizer.bypassSecurityTrustResourceUrl('data:text/html;base64,' + data);

  }
  getHtml(data: string) {
    return decodeURIComponent(atob(atob(data.substring(1, data.length - 1))).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

}