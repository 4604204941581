import { GPPPerson } from './gpp_person';
import { GPPEntity } from './gpp_entity';

export class GPPPayer {
    payerId: string;
    isResident: boolean;
    isLegal: boolean;
    person: GPPPerson;
    entity: GPPEntity;
}
