import { OnInit, Component, Inject, ViewChild, ElementRef, AfterViewInit, AfterViewChecked, HostListener } from '@angular/core';
import { HomeService } from '../../home.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/language.service';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SalesCenter } from 'src/app/models/sales_center';
import { CartItem } from 'src/app/models/cart_item';
import { OrderTime } from 'src/app/models/order_time';
import { AccountService } from '../../account-service';
import { Title, DomSanitizer, Meta } from '@angular/platform-browser';
import { SelectChange } from 'src/app/models/select_change';
import { Person } from 'src/app/models/person';
import { OrderView } from 'src/app/models_view/order_view';
import { Router } from '@angular/router';
import { stringify } from 'querystring';
import { Page } from 'src/app/models/page';
import { MatDialog } from '@angular/material/dialog';
import { DialogData2 } from '../orderdetails/orderDetails.component';

import html2canvas from 'html2canvas';
import { Overlay } from '@angular/cdk/overlay';
import { RulesDialog } from './rules-dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from 'src/app/storage.service';

@Component({
  selector: 'app-order',
  templateUrl: './checkout.component.html',
  styleUrls: ['../../index/index.component.scss']
})

export class orderCheckOutComponent implements OnInit {
  indexService: HomeService;
  orderForm: FormGroup;
  accepment: boolean = false;
  sales_center_list: SalesCenter[];
  order_by_date_list: OrderTime[];
  order_by_time_list: OrderTime[];
  order_view: OrderView;
  error_list: any;
  homeService: HomeService;
  person: Person;
  terms_cond: Page;
  count: number;
  buttonDisable: boolean = true;
  count_bread: number = 0;
  cart_list: CartItem[] = [];
  select_list: SelectChange = new SelectChange;
  saleCenter: string;
  constructor(overlay: Overlay, private meta: Meta, private storageService: StorageService, public dialog: MatDialog, private _sanitizer: DomSanitizer, public router: Router, homeService: HomeService, private TitleService: Title, private accountService: AccountService, public fb: FormBuilder, private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private ng7DynamicBreadcrumbService_en: Ng7DynamicBreadcrumbService, private translate: TranslateService, public langService: LanguageService, indexService: HomeService) {
    this.indexService = indexService;
    this.meta.updateTag({ property: 'og:title',content: "YUBİLEY VƏ XATİRƏ PUL NİŞANLARININ ONLAYN SATIŞ SİSTEMİ"});
    this.meta.updateTag({ property: 'og:description',content: "Azərbaycan Respublikasının Mərkəzi Bankı tərəfindən mütəmadi olaraq ölkəmizin tarixinə, mədəniyyətinə, elminə, incəsənətinə, eləcə də idman, görkəmli şəxsiyyətlər, əlamətdar hadisələr və s. mövzulara həsr olunmuş yubiley və xatirə pul nişanları tədavülə buraxılır."});
    this.meta.updateTag({ property: 'og:image:secure_url',content: window.location.origin +  "/" +"assets/images/e-cbar-3.png" });


    this.homeService = homeService;
    this.goto_home();
    this.is_loggin();
    this.count = 0;
    this.getSalesCenters();
    this.getTerms();
    this.getOrderModel();
    translate.setDefaultLang(this.langService.getLang().toLowerCase());
    this.translate.use(this.langService.getLang().toLowerCase());
  }

  getImageBase64(data: any) {
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + data);
  }

  goto_home() {
    if (this.storageService.getJsonValue("cart") == null) {
      this.router.navigate(["/home"]);
    }
    this.homeService.payment_check_date().subscribe(
      ((data) => {
        if (data == "No") {
          this.router.navigate(["/home"]);
        }
      }));

  }

  save() {

    if (!this.orderForm.valid) {
      return;
    }

    // this.cart_list = JSON.parse(localStorage.getItem("cart"));
    this.cart_list = this.storageService.getJsonValue("cart");
    const helper = new JwtHelperService();
    if (localStorage.getItem("coin_token")) {
      let d = helper.decodeToken(localStorage.getItem("coin_token"));
      this.orderForm.get("userId").setValue(parseInt(d["UserID"]));
    }
    this.orderForm.get("firstName").setValue(this.person.firstName);
    this.orderForm.get("lastName").setValue(this.person.lastName);
    this.orderForm.get("middleName").setValue(this.person.middleName);
    this.orderForm.get("serialNumber").setValue(this.person.serialNumber);
    this.orderForm.get("pin").setValue(this.person.pin);
    this.orderForm.get("cart").setValue(this.cart_list);
    // this.orderForm.get("totalPrice").setValue(localStorage.getItem("total_price"));
    this.orderForm.get("totalPrice").setValue(this.storageService.getJsonValue("total_price")
    );

    this.order_view = this.orderForm.value;
    //console.log(this.order_view)
    this.homeService.getOrderError(this.order_view).subscribe(
      ((data) => {
        this.error_list = data;
        if (this.error_list.length == 0) {
          this.homeService.getS_Center(parseInt(this.orderForm.get("salesCenterId").value)).subscribe(
            ((data) => {
              this.homeService.getO_Time(parseInt(this.orderForm.get("orderTimeId").value)).subscribe(
                ((data2) => {

                  this.orderForm.get("salesCenter").setValue(data);
                  this.orderForm.get("orderTime").setValue(data2);
                  this.orderForm.get("image_Person").setValue(this.person.image_Person);

                  // localStorage.setItem("order_complete", JSON.stringify(this.orderForm.value));
                  this.storageService.setJsonValue("order_complete", this.orderForm.value);

                  localStorage.removeItem("doc_code");
                  localStorage.removeItem("pay_status");
                  this.router.navigate(["/order/orderComplete"]);

                }));

            }));
        }
        else {
          this.scrollToTop();
        }
      }
      ));
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngOnInit() {
    this.getOrderModel();
    // this.translate.use(this.langService.getLang().toLowerCase());
    let title;
    let cart;
    if (this.langService.getLang() == 'az') {
      title = 'Şəxsi məlumatlar';
      cart = 'Səbət';
    } else if (this.langService.getLang() == 'en') {
      title = 'Personal information';
      cart = 'Cart';
    }
    const breadcrumb = { Title: title, cart: cart };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }



  setTitle(title: string) {

    this.TitleService.setTitle(title + ' | E-CBAR.AZ');

  }

  getSalesCenters() {
    this.homeService.getSalesCenters().subscribe(
      ((data) => {
        this.sales_center_list = this.order_by(data);
      }
      ));
  }

  order_by(filtr: SalesCenter[]) {
    filtr = filtr.sort((a, b) => a["centerName_" + this.langService.getLang().toUpperCase()]
      .localeCompare(b["centerName_" + this.langService.getLang().toUpperCase()]));
    return filtr;
  }

  getTerms() {
    this.homeService.getTermsAndConditions().subscribe(
      ((data) => {
        this.terms_cond = data;
        // console.log(data);
      }

      ));
  }

  openDialog() {
    const dialogRef = this.dialog.open(RulesDialog, {
      width: '700px',
      height: 'auto',
      maxHeight:'100vh',
      autoFocus: false,
      data: { data: this.terms_cond },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      result == true ? this.accepment = result : this.accepment = this.accepment;
      if (this.accepment) {
        this.buttonDisable = false;
      } else {
        this.buttonDisable = true;
      }
    });
  }

  is_loggin() {

    if (localStorage.getItem("coin_token") != null) {
      if (this.count == 0) {
        this.count++;
        this.getOrderModel();
      }
    }

  }
  getOrderModel() {

    this.homeService.getOrderModel().subscribe(
      ((data) => {
        this.person = data;

        this.orderForm = this.fb.group({

          userId: 0,
          lastName: [this.person.lastName],
          firstName: [this.person.firstName],
          middleName: [this.person.middleName],
          phone: ['', [Validators.required]],
          email: ['', [Validators.required]],
          serialNumber: [this.person.serialNumber],
          pin: [this.person.pin],
          salesCenterId: [0, [Validators.required]],
          orderTimeId: [0, [Validators.required]],
          salesCenter: SalesCenter,
          cart: [],
          orderTime: OrderTime,
          image_Person: String,
          totalPrice: 0


        });
      }
      ));


  }


  public mychange(event) {
    console.log(this.accepment);
    if (this.accepment) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = true;
    }
  }

  onChange(event, n: number) {

    if (n == 1) {
      if (event) {
        this.saleCenter = this.sales_center_list.find(c => c.id == event)['centerName_' + this.langService.getLang().toUpperCase()];
      }
      this.select_list.value_1 = event;
      this.order_by_time_list = [];
      this.order_by_date_list = [];
      this.select_list.value_2 = "null";
      this.select_list.value_3 = "null";
    }
    else if (n == 2) {
      this.select_list.value_2 = event;
      this.order_by_time_list = [];
      this.select_list.value_3 = "null";
    }
    else {
      this.select_list.value_3 = event;
    }


    if (this.select_list.value_1 != "null" && this.select_list.value_2 == "null") {
      this.homeService.getOrderTimeById(parseInt(this.select_list.value_1)).subscribe(
        ((data) => {
          this.order_by_date_list = data;
        }
        ));
    }
    else if (this.select_list.value_1 != "null" && this.select_list.value_2 != "null" && this.select_list.value_3 == "null") {
      this.homeService.getOrderTimeByDate(parseInt(this.select_list.value_2), parseInt(this.select_list.value_1)).subscribe(
        ((data) => {
          this.order_by_time_list = data;

        }
        ));
    }

  }

  // is_loggin() {
  //   // if (localStorage.getItem("coin_token") == null) {
  //   //   window.location.href = this.accountService.getAsanURLLogin() + "order/checkout";
  //   // }
  // }



  get lastName() { return this.orderForm.get('lastName'); }
  get firstName() { return this.orderForm.get('firstName'); }
  get middleName() { return this.orderForm.get('middleName'); }
  get phone() { return this.orderForm.get('phone'); }
  get email() { return this.orderForm.get('email'); }
  get serialNumber() { return this.orderForm.get('serialNumber'); }
  get salesCenterId() { return this.orderForm.get('salesCenterId'); }
  get orderTimeId() { return this.orderForm.get('orderTimeId'); }
  get salesCenter() { return this.orderForm.get('salesCenter'); }
  get cart() { return this.orderForm.get('cart'); }
  get orderTime() { return this.orderForm.get('orderTime'); }
  get totalPrice() { return this.orderForm.get('totalPrice'); }
  get pin() { return this.orderForm.get('pin'); }
  get image_Person() { return this.orderForm.get('image_Person'); }



}
