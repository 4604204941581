import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../home.service';
import { AccountService } from '../account-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Page } from 'src/app/models/page';
import { MatDialog } from '@angular/material/dialog';
import { UserGuideDialog } from '../dialog-box/user_guide';
import { AppConfig } from 'src/app/app.config';
import { StorageService } from 'src/app/storage.service';
import { InvestGuideDialogComponent } from '../dialog-boxes/invest-guide-dialog/invest-guide-dialog.component';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class breadcrumbcomponent implements OnInit {
  asanloginAuthURI: string | undefined;
  asan_client_id: string | undefined;
  asan_redirect_uri: string | undefined;
  asanUrl: string;
  userguide: Page;
  investSaleGuide: Page;
  config: string;
  homeService: HomeService;
  breadcrumbConfig: object = {
    bgColor: '#fff',
    fontSize: '14px',
    fontColor: '#999999',
    lastLinkColor: '#000',
    symbol: ""

  };
  currentRoute: string


  constructor(public dialog: MatDialog, private storageService: StorageService, HomeService: HomeService, private router: Router, private accountService: AccountService) {
    this.is_come_token();
    this.asanUrl = "";
    this.config = AppConfig.settings.urls.homeUrl;
    this.asanloginAuthURI = AppConfig.settings.asan.asanloginAuthUrl;
    this.asan_client_id = AppConfig.settings.asan.asan_client_id;
    this.asan_redirect_uri = AppConfig.settings.asan.asan_redirect_url;
    this.homeService = HomeService;
    this.UserGuide();
    this.InvestSaleGuide();
    this.currentRoute = this.router.url;
  }

  ngOnInit() {

  }
  //User guide
  UserGuide() {
    this.homeService.getUserGuide().subscribe(
      ((data) => {
        this.userguide = data;
      }
      ));
  }
  asan_login() {
    this.accountService.getStateId().subscribe((res: any) => {
    let state_id = res;
    let client_id = this.asan_client_id;
    let redirect_uri = this.asan_redirect_uri;
    let authUrl = this.asanloginAuthURI!;
    let url = authUrl + '?client_id=' + client_id + '&' + 'redirect_uri=' + redirect_uri + '&' + 'response_type=code' + '&' + 'state=' + state_id + '&' + 'scope=openid certificate';
    window.location.href = url;
  });
  }
  //Invest sale guide
  InvestSaleGuide() {
    this.homeService.getInvestSaleGuide().subscribe(
      ((data) => {
        this.investSaleGuide = data;
      }
      ));
  }
  openDialog(key:string) {
    if(key=="user"){
      const dialogRef = this.dialog.open(UserGuideDialog, {
        width: '900px',
        height: 'auto',
        data: { data: this.userguide },
        autoFocus: false,
        disableClose: true
      });
    }
    else if(key=="invest"){
      const dialogRef = this.dialog.open(InvestGuideDialogComponent, {
        width: '900px',
        height: 'auto',
        data: { data: this.investSaleGuide },
        autoFocus: false,
        disableClose: true
      });
    }
  }
  login() {
    window.location.href = this.accountService.getAsanURLLogin() + this.router.url.toString().substr(1);
  }

  logout() {
    window.location.href = this.accountService.getAsanURLLogout() + "home";
  }
  is_come_token() {
    this.accountService.getAsanTokeTestMain().subscribe((data: any) => {
      this.accountService.getAsanCheck(data).subscribe((data2: any) => {

        this.accountService.getMyToken(data2).subscribe((token: any) => {

          localStorage.setItem("coin_token", token.value.token);
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(localStorage.getItem("coin_token"));
          localStorage.setItem("user_id", decodedToken["UserID"]);
        });
      });


    });
  }
  cart_count() {
    if (this.storageService.getJsonValue("cart_amount")) {
      return this.storageService.getJsonValue("cart_amount");
    }
    else {
      return false;
    }

  }

  is_loggin() {
    if (localStorage.getItem("coin_token") != null) {
      const helper = new JwtHelperService();

      const decodedToken = helper.decodeToken(localStorage.getItem("coin_token"));

      // Other functions

      let user = decodedToken["UserName"] + " "
        + decodedToken["LastName"] + " "
        + decodedToken["MiddleName"];

      return user;

    }
    else {
      return "is_not_login";
    }
  }

  go_cabinet() {
    this.router.navigate(["/Cabinet/Orders"]);
  }



}