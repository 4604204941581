import { Component, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/language.service';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { HomeService } from '../../home.service';
import { OrderCoin } from 'src/app/models/order_coin';
import { Order } from 'src/app/models/order';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

export class OrderComponent implements OnInit {
  @ViewChild(MatPaginator, {/*static: true*/ }) paginator: MatPaginator;
  order_list: Order[];
  public dataSource: any;    
  public pageSize = 8;
  public currentPage = 0;
  public totalSize = 0;
  pageEvent: PageEvent;
  order_length: number;
  constructor(public router: Router, private meta: Meta, public homeService: HomeService, private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private translate: TranslateService, public langService: LanguageService, private TitleService: Title) {
    translate.setDefaultLang(this.langService.getLang().toLowerCase());
    this.translate.use(this.langService.getLang().toLowerCase());
    this.meta.updateTag({ property: 'og:title',content: "YUBİLEY VƏ XATİRƏ PUL NİŞANLARININ ONLAYN SATIŞ SİSTEMİ"});
    this.meta.updateTag({ property: 'og:description',content: "Azərbaycan Respublikasının Mərkəzi Bankı tərəfindən mütəmadi olaraq ölkəmizin tarixinə, mədəniyyətinə, elminə, incəsənətinə, eləcə də idman, görkəmli şəxsiyyətlər, əlamətdar hadisələr və s. mövzulara həsr olunmuş yubiley və xatirə pul nişanları tədavülə buraxılır."});
    this.meta.updateTag({ property: 'og:image:secure_url',content: window.location.origin +  "/" +"assets/images/e-cbar-3.png" });


    this.getMyOrders();
  }
  ngOnInit() {
    this.translate.use(this.langService.getLang().toLowerCase());

    let title;
    let cart;

  }
  goto_order(id, number) {
    this.router.navigate(["/Cabinet/Order/",id], {queryParams: {number: `${number}`}});
  }
  
  getMyOrders() {
    this.homeService.getMyOrders().subscribe(
      ((data: Order[]) => {
        this.order_list = data;
        this.dataSource = new MatTableDataSource<Order>(this.order_list);
        this.order_length = this.order_list.length;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.order_list.length;
        this.iterator();
       
      }));
  }
  get_txt(data){
    return data.replace(/Ə/g, 'ə');
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
    return e;
  }
  
  
  
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.order_list.slice(start, end);
    this.dataSource = part;
  }


  setTitle(title: string) {
    this.TitleService.setTitle(title + ' | E-CBAR.AZ');
    if (this.langService.getLang() == 'az') {
      title = 'Sifarişlərim';
    } else if (this.langService.getLang() == 'en') {
      title = 'My Orders';
    }
    const breadcrumb = { Title: title };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);

  }

  is_loggin() {
    if (localStorage.getItem("coin_token") == null) {
      return false;
    }
    else {
      return true;
    }
  }
}