import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../account-service';

@Component({
  selector: 'app-oauth-redirect',
  template: ``,
  styleUrls: ['./oauth-redirect.component.sass']
})
export class OauthRedirectComponent implements OnInit {
  code: string;
  state: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      const state = params['state']; // Replace 'paramName' with your actual parameter name
      if (code) {
        this.code = code;
        this.state = state;
        this.logIn();
      }
    });
  }
  
  logIn() {
    this.accountService.getStateId().subscribe((res: any) => {
    this.accountService
      .loginEasy({authorizationCode: this.code, State: this.state})
      .subscribe({
          next: (res) => {
            debugger;
            if (res.value.token) {
              localStorage.setItem('coin_token', res.value.token);
              this.router.navigate(['Cabinet/Orders']);
            } else {
              this.router.navigate(['']);
            }
          },
          error: (res) => {
            
            (res.status === 400 || res.error.status === 500) && this.router.navigate(['']);
          }
        }
      );
    });
  }
  

}
