import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/language.service';
import { AccountService } from '../account-service';

@Component({
     selector: 'app-cabinet',
     templateUrl: './cabinet.component.html',
     styleUrls: ['./cabinet.component.scss']
})

export class CabinetComponent implements OnInit {

     constructor(public accountService: AccountService, private meta: Meta, private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private translate: TranslateService, private langService: LanguageService, private TitleService: Title) {
          this.meta.updateTag({ property: 'og:title',content: "YUBİLEY VƏ XATİRƏ PUL NİŞANLARININ ONLAYN SATIŞ SİSTEMİ"});
          this.meta.updateTag({ property: 'og:description',content: "Azərbaycan Respublikasının Mərkəzi Bankı tərəfindən mütəmadi olaraq ölkəmizin tarixinə, mədəniyyətinə, elminə, incəsənətinə, eləcə də idman, görkəmli şəxsiyyətlər, əlamətdar hadisələr və s. mövzulara həsr olunmuş yubiley və xatirə pul nişanları tədavülə buraxılır."});
          this.meta.updateTag({ property: 'og:image:secure_url',content: window.location.origin +  "/" +"assets/images/e-cbar-3.png" });

     }
     ngOnInit() {

     }
     setTitle(title: string) {
          this.TitleService.setTitle(title + ' | E-CBAR.AZ');
          if (this.langService.getLang() == 'az') {
               title = 'Kabinet';

          } else if (this.langService.getLang() == 'en') {
               title = 'Cabinet';

          }
          const breadcrumb = { Title: title };
          this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
     }
     is_loggin() {
          if (localStorage.getItem("coin_token") == null) {
               return false;
          }
          else {
               return true;
          }
     }

     get_txt(data) {
          if (this.langService.getLang() === 'az') {
               return data.replace(/i/g, 'İ');
          }
          else {
               return data.replace(/i/g, 'i');
          }
     }
}