import { OnInit, Component } from '@angular/core';
import { HomeService } from '../../home.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-order',
    templateUrl: './orderDone.component.html',
    styleUrls: ['../../index/index.component.scss']
  })

  export class orderDoneComponent implements OnInit {
    indexService: HomeService;
constructor(private TitleService :Title,private translate: TranslateService,indexService: HomeService) {
      this.indexService = indexService;
    
       }
    
      ngOnInit() {
     
      }
  }