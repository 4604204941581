import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IndexComponent, CustomMatPaginatorIntl } from './index/index.component';
import { HomeRoutingModule } from './home-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { DetailsComponent } from './details/details.component';
import { cartComponent } from './cart/cart.component';
import { customerComponent } from './customer/customer.component';
import { orderCheckOutComponent } from './order/checkout/checkout.component';
import { orderDoneComponent } from './order/orderDone/orderDone.component';
import { orderPdfComponent } from './order/orderPdf/orderPdf.component';
import { orderCompleteComponent, DialogComponent } from './order/orderComplete/orderComplete.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CabinetComponent } from './cabinet/cabinet.component';
//Capcha
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';


import { MatTooltipModule } from '@angular/material/tooltip';
import { OrderComponent } from './order/orders/order.component';
import { OrderDetailsComponent, DialogComponent2 } from './order/orderdetails/orderDetails.component';
import { UserGuideDialog } from './dialog-box/user_guide';
import { ReverseString } from './order/checkout/custom-date-format.pipe';
import { RulesDialog } from './order/checkout/rules-dialog';
import { GppPaymentDialog } from './order/orderComplete/gpp-payment/gpp_payment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InvestGuideDialogComponent } from './dialog-boxes/invest-guide-dialog/invest-guide-dialog.component';
import { OauthRedirectComponent } from './oauth-redirect/oauth-redirect.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [GppPaymentDialog, RulesDialog, UserGuideDialog, DialogComponent, DialogComponent2, ReverseString, IndexComponent, DetailsComponent, OrderComponent, OrderDetailsComponent, CabinetComponent, cartComponent, customerComponent, orderCheckOutComponent, orderPdfComponent, orderDoneComponent, orderCompleteComponent, InvestGuideDialogComponent, OauthRedirectComponent],
  imports: [
    MatPaginatorModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatInputModule,
    CommonModule,
    RouterModule,
    MatDialogModule,
    HomeRoutingModule,
    FormsModule,
    RecaptchaModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [
    DialogComponent, DialogComponent2, RulesDialog, UserGuideDialog, GppPaymentDialog
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS, useValue: {
        siteKey: '6Le8etQUAAAAANCpJlqkrawgb0hr-4h8qA9MVlAz',
      } as RecaptchaSettings
    },
    {
      provide: MatPaginatorIntl, 
      useClass: CustomMatPaginatorIntl
    }
  ]
})
export class HomeModule { }
