import { Coin } from './coin';
import { Order } from './order';

export class OrderTime {
    id : number;
    orderTimeDate: Date;
    orderTimeStatus: boolean;
    salesCenterId : number;
    cnOrder : Order[];
    timeOnly : string;
    dateOnly : string;
}