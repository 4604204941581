import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {IndexComponent} from './index/index.component';
import {Shell} from './../shell/shell.service';
import {HomeService} from './home.service';
import {DetailsComponent} from './details/details.component';
import {cartComponent} from './cart/cart.component';
import {orderCheckOutComponent} from './order/checkout/checkout.component';
import {orderDoneComponent} from './order/orderDone/orderDone.component';
import {orderCompleteComponent} from './order/orderComplete/orderComplete.component';
import {AccountService} from './account-service';
import {CabinetComponent} from './cabinet/cabinet.component';
import {OrderComponent} from './order/orders/order.component';
import {OrderDetailsComponent} from './order/orderdetails/orderDetails.component';
import {OauthRedirectComponent} from './oauth-redirect/oauth-redirect.component';


const routes: Routes = [
  Shell.childRoutes([
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'oauth-redirect', component: OauthRedirectComponent},
    {
      path: 'home',
      component: IndexComponent,
      data: {
        breadcrumb: []
      }
    },
    {
      path: 'home/:id',
      component: IndexComponent,
      data: {
        breadcrumb: []
      }
    },
    {
      path: 'Cabinet',
      component: CabinetComponent,
      data: {
        breadcrumb: [
          {
            label: '{{Title}}',
            url: '/Cabinet'
          }
        ]
      }
    },
    {
      path: 'Cabinet/Orders',
      component: OrderComponent,
      data: {
        breadcrumb: [
          {
            label: '{{Title}}',
            url: '/Cabinet/Orders'
          }
        ]
      }
    },
    {
      path: 'Cabinet/Order/:id',
      component: OrderDetailsComponent,
      data: {
        breadcrumb: [
          {
            label: '{{Order}}',
            url: '/Cabinet/Orders'
          },
          {
            label: '{{Title}}',
            url: '/Cabinet/Order/:id'
          }
        ]
      }
    },
    {
      path: 'cart/index',
      component: cartComponent,
      data: {
        breadcrumb: [
          {
            label: '{{Title}}',
            url: '/cart/index'
          }
        ]
      }
    },
    {
      path: 'coin/details/:id',
      component: DetailsComponent,
      data: {
        breadcrumb: [
          {
            label: '{{Title}}',
            url: '/home/:id'
          }
        ]
      }
    },
    {
      path: 'order',
      children: [
        {
          path: 'checkout',
          component: orderCheckOutComponent,
          data: {
            breadcrumb: [
              {
                label: '{{cart}}',
                url: '/cart/index'
              },
              {
                label: '{{Title}}',
                url: '/order/checkout'
              }
            ]
          }
        },
        {
          path: 'orderDone',
          component: orderDoneComponent,
          data: {
            breadcrumb: []
          }
        },
        {
          path: 'orderComplete',
          component: orderCompleteComponent,
          data: {
            breadcrumb: [
              {
                label: '{{cart}}',
                url: '/cart/index'
              },
              {
                label: '{{Title}}',
                url: '/order/orderComplete'
              }
            ]
          }
        },
        {
          path: 'orderComplete/:id',
          component: orderCompleteComponent,
          data: {
            breadcrumb: [
              {
                label: '{{cart}}',
                url: '/cart/index'
              },
              {
                label: '{{Title}}',
                url: '/order/orderComplete'
              }
            ]
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [HomeService, AccountService]
})

export class HomeRoutingModule {
}
