import { LanguageService } from 'src/app/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Inject, Component } from '@angular/core';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import { Page } from 'src/app/models/page';
import { HomeService } from '../home.service';

@Component({
  selector: 'user-guide-dialog',
  templateUrl: './user_guide.html',
  styleUrls: ['.//user_guide.scss']

})
export class UserGuideDialog {
  data_page: Page;
  data_pdf: any;
  data_source: any;
  userguide: any;
  showSpinner: boolean;
  constructor(public homeService: HomeService,
    public langService: LanguageService, private translate: TranslateService,
    public dialogRef: MatDialogRef<UserGuideDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _sanitizer: DomSanitizer) {
    this.data_page = data.data;

  }


  getTranslatedText(key: string) {
    return this.translate.get(key);
  }

  public convetToPDF() {
    let img = new Image();
    img.src = "../../../assets/images/space.png";

    this.showSpinner = true;
    document.getElementById('x').innerHTML = "<html><h4>" + this.data_page["header_" + this.langService.getLang().toUpperCase()] + "</h4><p style='text-align:justify!important;'>" + this.data_page["description_" + this.langService.getLang().toUpperCase()] + "</p></html>";
    var p_content = document.getElementById('x');

    html2canvas(p_content,
      {
        scale: 2
      }
    ).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 200;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var pdf = new jspdf.jsPDF('p', 'mm');
      var position = 15; // give some top padding to first page

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages

        pdf.addImage(img, 'PNG', 0, 276, imgWidth, 25);

        pdf.addPage();

        pdf.addImage(imgData, 'PNG', 0, position + 44, imgWidth, imgHeight);

        pdf.addImage(img, 'PNG', 0, 0, imgWidth, 25);

        heightLeft -= pageHeight;

      }

      pdf.save('User guide.pdf');
      this.showSpinner = false;

    });
    document.getElementById('x').innerHTML = null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  decodeReceipt(data: string) {
    data = atob(data.substring(1, data.length - 1));
    this.data_source = this._sanitizer.bypassSecurityTrustResourceUrl('data:text/html;base64,' + data);
  }
  getHtml(data: string) {
    return decodeURIComponent(atob(atob(data.substring(1, data.length - 1))).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

}