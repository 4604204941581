import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './home/index/index.component';

 

export const routes: Routes = [
 // { path: 'auth-callback', component: AuthCallbackComponent  },
  // Fallback when no prior route is matched
 // { path: '**', redirectTo: '', pathMatch: 'full' }

  {path: '', redirectTo: 'home', pathMatch: 'full'},  
    {path: 'home',component: IndexComponent 
       
    }  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
