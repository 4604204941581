import { GPPPayer } from './gpp_payer';
import { GPPInvoice } from './gpp_invoice';
import { GPPPayment } from './gpp_payment';
import { GPPGppDetails } from './gpp_gppDetails';
import { GPPClientAppDetails } from './gpp_clientAppDetails';

export class GPPInvoicePayload {
    payer:GPPPayer;
    invoice:GPPInvoice;
    payment:GPPPayment;
    gppDetails:GPPGppDetails;
    clientAppDetails:GPPClientAppDetails;
}
