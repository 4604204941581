import { OnInit, Component } from '@angular/core';
import { HomeService } from '../home.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ItemImage } from 'src/app/models_view/item_image';
import { CartItem } from 'src/app/models/cart_item';
import { CoinImage } from 'src/app/models/coin_image';
import { CoinAmount } from 'src/app/models/coin_amount';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { LanguageService } from 'src/app/language.service';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { AccountService } from '../account-service';
import { StorageService } from 'src/app/storage.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['../index/index.component.scss']
})

export class cartComponent implements OnInit {
  homeService: HomeService;
  id: number;
  imagemodel: ItemImage = new ItemImage;
  cart: CartItem[];
  imagelist: CoinImage[];
  coinimages: CoinImage;
  cartitem: CartItem;
  amounts: CoinAmount[];
  sum_cart: number = 0.0;
  payment_active_date_status: string;
  constructor(private TitleService: Title, private meta: Meta, private storageService: StorageService, private accountService: AccountService, private router: Router, private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, private langService: LanguageService, private _sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute, private translate: TranslateService, homeService: HomeService) {
    this.homeService = homeService;
    this.meta.updateTag({ property: 'og:title',content: "YUBİLEY VƏ XATİRƏ PUL NİŞANLARININ ONLAYN SATIŞ SİSTEMİ"});
    this.meta.updateTag({ property: 'og:description',content: "Azərbaycan Respublikasının Mərkəzi Bankı tərəfindən mütəmadi olaraq ölkəmizin tarixinə, mədəniyyətinə, elminə, incəsənətinə, eləcə də idman, görkəmli şəxsiyyətlər, əlamətdar hadisələr və s. mövzulara həsr olunmuş yubiley və xatirə pul nişanları tədavülə buraxılır."});
    this.meta.updateTag({ property: 'og:image:secure_url',content: window.location.origin +  "/" +"assets/images/e-cbar-3.png" });

    
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.check_p_date();
      this.cart_index();
      this.sum_cart = 0.0;
    });

  }
  ngOnInit() {
    let title;
    if (this.langService.getLang() == 'az') {
      title = 'Səbət';
    } else if (this.langService.getLang() == 'en') {
      title = 'Cart';

    }
    const breadcrumb = { Title: title };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
  }
  transform(value: number): number {
    return value;

  }
  setTitle(title: string) {
    this.TitleService.setTitle(title + ' | E-CBAR.AZ');

  }
  check_p_date() {
    this.homeService.payment_check_date().subscribe(
      ((data) => {
        if (data == "Ok") {
          this.payment_active_date_status = data;
        } else if (data == "No") {
          if (this.langService.getLang().toLowerCase() == "az") {
            this.payment_active_date_status = "Siz qeyri iş günləri sifariş edə bilməzsiniz";
          } else {
            this.payment_active_date_status = "You can not order non-working days"
          }
        } else {
          if (this.langService.getLang().toLowerCase() == "az") {
            this.payment_active_date_status = "ŞİFARİŞ VAXTI: iş günləri " + data.split("|")[0].substr(0, 5) + "-" + data.split("|")[1].substr(0, 5);
          } else {
            this.payment_active_date_status = "ORDER TIME: working days " + data.split("|")[0].substr(0, 5) + "-" + data.split("|")[1].substr(0, 5);
          }
        }

        //console.log(data)
      }));
  }

  cart_index() {

    // this.cart = JSON.parse(localStorage.getItem('cart'));
    this.cart = this.storageService.getJsonValue("cart");
    if (this.cart == null) {
      this.router.navigate(["/home"]);
    }
    else {
      this.imagemodel.itemCartList = this.cart;
      this.imagemodel.itemImage = [];
      // localStorage.setItem('cart_amount', JSON.stringify(this.cart.length));
      this.storageService.setJsonValue("cart_amount", this.cart.length);

      let cartList = this.cart;

      var a = [];
      cartList.forEach((item, index) => {
            item.maxCount = 0;
            this.homeService.getCoinAmounts(item.coin.id).subscribe(
              ((data: CoinAmount[]) => {
                this.amounts = data;
                let pList = this.amounts;
                pList.forEach(amount => {
                  if (item.maxCount < amount.quantity) {
                    item.maxCount = amount.quantity;
                    // if (item.maxCount > item.coin.dailyLimit) {
                    //   item.maxCount = item.coin.dailyLimit;
                    // }
                  }
                });
              }));
      });

    }

  }
  get_txt(data) {
    return data.replace(/Ə/g, 'ə');
  }

  sum() {
    this.sum_cart = 0.0;
    for (let i = 0; i < this.imagemodel.itemCartList.length; i++) {
      this.sum_cart += this.imagemodel.itemCartList[i].coin.price * this.imagemodel.itemCartList[i].quantity;
    }
    // localStorage.setItem("total_price", this.sum_cart.toString());
    this.storageService.setJsonValue("total_price", this.sum_cart);

    return this.sum_cart;
  }
  sum_displayblock() {
    this.sum_cart = 0.0;
    for (let i = 0; i < this.imagemodel.itemCartList.length; i++) {
      this.sum_cart += this.imagemodel.itemCartList[i].coin.price * this.imagemodel.itemCartList[i].quantity;
    }
    // localStorage.setItem("total_price", this.sum_cart.toString());
    this.storageService.setJsonValue("total_price", this.sum_cart);
  }
  getImageBase64(coin_image: CoinImage[], id: number) {
    for (let i = 0; i < coin_image.length; i++) {
      if (coin_image[i].coinId == id && coin_image[i].imageType == 1) {
        return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + coin_image[i].imageData);
        break;
      }
    }


  }

  deacreaseQuantity(id: number) {
    // this.cart = JSON.parse(localStorage.getItem('cart'));
    this.cart = this.storageService.getJsonValue("cart");
    for (let i = 0; i < this.cart.length; i++) {
      if (this.cart[i].coin.id == id) {
        this.cart[i].quantity--;
      }


    }
    this.imagemodel.itemCartList = this.cart;
    // localStorage.setItem('cart', JSON.stringify(this.cart));
    this.storageService.setJsonValue("cart", this.cart);

    this.cart_index();
    this.sum_cart = 0.0;
    this.sum();
  }

  increaseQuantity(id: number, price: number) {

    // this.cart = JSON.parse(localStorage.getItem('cart'));
    this.cart = this.storageService.getJsonValue("cart");
    for (let i = 0; i < this.cart.length; i++) {
      if (this.cart[i].coin.id == id) {
        this.cart[i].quantity++;
      }
    }

    this.imagemodel.itemCartList = this.cart;
    // localStorage.setItem('cart', JSON.stringify(this.cart));
    this.storageService.setJsonValue("cart", this.cart);

    this.cart_index();
    this.sum_cart = 0.0;
    this.sum();
  }

  remove(id: number) {
    // this.cart = JSON.parse(localStorage.getItem('cart'));
    this.cart = this.storageService.getJsonValue("cart");
    var index;
    index = this.isExist(id);
    this.cart.splice(index, 1);
    this.imagemodel.itemCartList = this.cart;
    // localStorage.setItem('cart', JSON.stringify(this.cart));
    this.storageService.setJsonValue("cart", this.cart);

    this.cart_index();
    this.sum_cart = 0.0;
    this.sum();
  }

  isExist(id: number) {
    // this.cart = JSON.parse(localStorage.getItem('cart'));
    this.cart = this.storageService.getJsonValue("cart");
    for (var i = 0; i < this.cart.length; i++) {
      if (this.cart[i].coin.id == id) {
        return i;
      }
    }
    return -1;
  }

  is_loggin() {

    if(this.homeService.hasExceedLimit(0)) return;

    if (localStorage.getItem("coin_token") != null) {
      this.router.navigate(["/order/checkout"]);
      localStorage.removeItem("email");
      localStorage.removeItem("pay_status");
    }
    else {
      window.location.href = this.accountService.getAsanURLLogin() + "cart/index";

    }
  }



}