import { OnInit, Component, Inject, ViewEncapsulation } from '@angular/core';
import { HomeService } from '../../home.service';
import { TranslateService } from '@ngx-translate/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { OrderView } from 'src/app/models_view/order_view';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { LanguageService } from 'src/app/language.service';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { AccountService } from '../../account-service';
import { OrderSaleView } from 'src/app/models_view/order_sale_view';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { GppPaymentDialog } from './gpp-payment/gpp_payment';
import { Order } from 'src/app/models/order';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from 'src/app/storage.service';

export interface DialogData {
  url: string;
}

@Component({
  selector: 'app-order',
  templateUrl: './orderComplete.component.html',
  styleUrls: ['../../index/index.component.scss']
})

export class orderCompleteComponent implements OnInit {
  homeService: HomeService;
  order_view: OrderView;
  order_sale_view: OrderSaleView;
  error_list: any;
  order: Order;
  orderId: number;
  personId: number;
  doc_code: string;
  my_order: any;
  pay_status: string;
  payment_date_check: string;
  constructor(public dialog: MatDialog, private storageService: StorageService, public activatedRoute: ActivatedRoute, public accountService: AccountService, private _sanitizer: DomSanitizer, public router: Router, private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService, homeService: HomeService, private TitleService: Title, private translate: TranslateService, public langService: LanguageService) {
    this.homeService = homeService;
    this.check_p_date();
    this.doc_code = localStorage.getItem("doc_code");
    this.pay_status = localStorage.getItem("pay_status");

    // this.order_view = JSON.parse(localStorage.getItem("order_complete"));
    this.order_view = this.storageService.getJsonValue("order_complete");
    if(this.order_view==null || this.order_view==undefined){
      this.router.navigate(["/"]);
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.doc_code = params['id'];
      if (this.doc_code) {
        this.check_payment();
      }

    });

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '1000px',
      data: { url: this.order_sale_view.payment_Receipt_Content },
      autoFocus: false,
      disableClose: true,

    });
  }


  getImageBase64(data: any) {
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + data);
  }

  openGpp() {
    const dialogRef = this.dialog.open(GppPaymentDialog, {
      width: '700px',
      height: 'auto',
      data: { data: this.my_order },
      disableClose: true

    });

  }





  check_p_date() {
    this.homeService.payment_check_date().subscribe(
      ((data) => {
        if (data == "Ok") {
          this.payment_date_check = data;
        } else if (data == "No") {
          if (this.langService.getLang().toLowerCase() == "az") {
            this.payment_date_check = "Siz qeyri iş günləri sifariş edə bilməzsiniz";
          } else {
            this.payment_date_check = "You can not order non-working days"
          }
        } else {
          if (this.langService.getLang().toLowerCase() == "az") {
            this.payment_date_check = "Siz sifarişi  iş günləri " + data.split("|")[0].substr(0, 5) + " dan " + data.split("|")[1].substr(0, 5) + " dək edə bilərsiniz";
          } else {
            this.payment_date_check = "You can order from " + data.split("|")[0].substr(0, 5) + " to " + data.split("|")[1].substr(0, 5) + " business days";
          }
        }

        //console.log(data)
      }));
  }

  save() {
    document.getElementById("spin").style.display = "block";
    document.getElementById("first_button").style.display = "none";
    this.homeService.getOrderError_NO(this.order_view).subscribe(
      ((data) => {
        this.error_list = data;
        if (this.error_list.length == 0) {
          this.homeService.getOrderComplete(this.order_view).subscribe(
            ((data) => {
              if (data.result != "Error happened while creating order.") {
                this.my_order = data.result;
                document.getElementById("spin").style.display = "none";
                this.openGpp();
                document.getElementById("first_button").style.display = "inline-block";
              }
            }
            ));
        } else {
          document.getElementById("spin").style.display = "none";
        }
      }));
  }

  ngOnInit() {
    let title;
    let cart;
    if (this.langService.getLang() == 'az') {
      title = 'Sifarişin Təsdiqi';
      cart = 'Səbət';
    } else if (this.langService.getLang() == 'en') {
      title = 'Order complete';
      cart = 'Cart';
    }
    const breadcrumb = { Title: title, cart: cart };
    this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);

    this.personId = this.homeService.get_userId_fromToken();
    this.orderId = parseInt(this.doc_code);

    if (this.orderId) {
      this.getOrderById(this.orderId);
    }

    if (localStorage.getItem("order_complete_token")) {
      const helper = new JwtHelperService();
      let d = helper.decodeToken(localStorage.getItem("order_complete_token"));
      this.order_view = JSON.parse(d["order_complete"]);
    }
  }

  getOrderById(id: number) {
    this.homeService.getOrderById(id).subscribe(
      ((data: Order) => {
        this.order = data;
        if (this.order.personId != this.personId) {
          this.router.navigate(['/']);
        }
      }));
  }

  copyText() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.doc_code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  setTitle(title: string) {

    this.TitleService.setTitle(title + ' | E-CBAR.AZ');

  }
  get_txt(data) {
    return data.replace(/Ə/g, 'ə');
  }


  check_payment() {
    this.homeService.getPaymentResult(this.doc_code).subscribe(
      ((data) => {
        this.order_sale_view = data;
        //  console.log(data);
        if (this.order_sale_view) {
          if (this.order_sale_view.payment_Amount && this.order_sale_view.payment_Amount == this.order_sale_view.amount_To_Pay) {
            this.homeService.setPayStatus(this.doc_code).subscribe(
              ((data) => {
                if (document.getElementById("disable_success")) {
                  document.getElementById("disable_success").style.display = "none";
                }
                if (document.getElementById("disable_success_2")) {
                  document.getElementById("disable_success_2").style.display = "none";
                }


                document.getElementById("myb_success").style.display = "inline-block";
                document.getElementById("my_dsuccess").style.display = "block";

                //console.log(data)
                this.pay_status == "true";
                localStorage.setItem("pay_status", "true");
                // localStorage.removeItem("cart");
                // localStorage.removeItem("total_price");
                this.storageService.remove("cart");
                this.storageService.remove("total_price");
                // localStorage.removeItem("cart_amount");
                this.storageService.remove("cart_amount")
                if (!localStorage.getItem("email")) {
                  this.homeService.sendEmail(this.doc_code).subscribe(
                    ((data) => {
                      if (data == "Email sent") {
                        localStorage.setItem("email", "ok");
                        this.storageService.remove("order_complete");
                      }
                      console.log(data)
                    }));
                }
              }));
          } else {
            this.looper();
          }
        }

      }));
  }

  looper() {
    var self = this;
    setTimeout(() => {
      self.check_payment();
    }, 1000)
  }

}

@Component({
  selector: 'dialog-component',
  templateUrl: 'dialog-component.component.html',
  styleUrls: ['../orderdetails/orderDetails.component.scss']
})
export class DialogComponent {
  data_pdf: any;
  data_source: any;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private _sanitizer: DomSanitizer) {
    this.decodeReceipt(data.url);
    this.data_pdf = this.getHtml(data.url);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public convetToPDF() {
    document.getElementById('x').innerHTML = this.data_pdf;
    var p_content = document.getElementById('x');
    html2canvas(p_content).then(canvas => {
      var imgWidth = 208;
      var imgHeight = 55;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 7;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('paymentReceiptContent.pdf');
    });
    document.getElementById('x').innerHTML = null;
  }


  getHtml(data: string) {
    return decodeURIComponent(atob(atob(data)).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  public decodeReceipt(data) {
    data = atob(data)
    this.data_source = this._sanitizer.bypassSecurityTrustResourceUrl("data:text/html;base64, " + data);
    // console.log(this.data_source)
  }

}