import { Component, ViewChild, ElementRef, OnInit, Inject } from '@angular/core';
import { Page } from 'src/app/models/page';
import { LanguageService } from 'src/app/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';

@Component({
  selector: 'rules-dialog',
  templateUrl: 'rules-dialog.html',
  styleUrls: ['./checkout.component.scss']

})
export class RulesDialog implements OnInit {
  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;

  data_page: Page;
  data_pdf: any;
  data_source: any;
  disableScrollDown = false;
  buttomDisable = false;
  showSpinner: boolean;

  constructor(
    public langService: LanguageService, private translate: TranslateService,
    public dialogRef: MatDialogRef<RulesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _sanitizer: DomSanitizer) {
    this.data_page = data.data;
    console.log(this.data_page)

  }

  ngOnInit(): void {
    console.log("ngOnInit");
    //this.onScroll();
  }


  getTranslatedText(key: string) {
    return this.translate.get(key);
  }

  public convetToPDF() {
    this.showSpinner = true;
    document.getElementById('x').innerHTML = "<html><h4>" + this.data_page["header_" + this.langService.getLang().toUpperCase()] + "</h4><p style='text-align:justify!important;'>" + this.data_page["description_" + this.langService.getLang().toUpperCase()] + "</p></html>";
    var p_content = document.getElementById('x');

    html2canvas(p_content,
      {
        scale: 2
      }
    ).then(canvas => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 200;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var pdf = new jspdf.jsPDF('p', 'mm');
      var position = 15; // give some top padding to first page

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages

        pdf.addPage();

        pdf.addImage(imgData, 'PNG', 0, position + 44, imgWidth, imgHeight);

        heightLeft -= pageHeight;
      }
      pdf.save('Payment agreement.pdf');
      this.showSpinner = false;

    });
    document.getElementById('x').innerHTML = null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  decodeReceipt(data: string) {
    data = atob(data.substring(1, data.length - 1));
    this.data_source = this._sanitizer.bypassSecurityTrustResourceUrl('data:text/html;base64,' + data);
  }
  getHtml(data: string) {
    return decodeURIComponent(atob(atob(data.substring(1, data.length - 1))).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

}
