import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {MatMenuTrigger} from '@angular/material/menu';
import {LoaderService} from 'src/app/loader.service';
import {LanguageService} from 'src/app/language.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AppConfig} from 'src/app/app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      state('false', style({'height': '0px', 'display': 'none'})),
      state('true', style({'height': '*'})),

      transition('false => true', [
        style({'display': 'block'}),
        animate('300ms ease-in')
      ]),

      transition('true => false', [
        animate('300ms ease-in')
      ])
    ]),
    trigger('openClose2', [
      state('false', style({'display': 'none'})),
      state('true', style({'display': 'block'}))
    ])
  ]
})

export class HeaderComponent implements OnInit, OnDestroy {
  name: string;
  isAuthenticated: boolean;
  subscription: Subscription;
  showProgress: boolean = true;
  visible: boolean = false;
  config: any;
  visibleLang = false
  encapsulation: ViewEncapsulation.None

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;


  constructor(public loaderService: LoaderService,
    private translate: TranslateService, private langService: LanguageService) {
    this.config = AppConfig.settings.urls;
    translate.setDefaultLang(this.langService.getLang().toLowerCase());
    this.loaderService.isLoading.subscribe((v) => {
      this.showProgress = v;
    });
  }

  useLanguage(language: string) {
    this.langService.setLang(language);
    this.translate.use(this.langService.getLang().toLowerCase());
  }

  lang() {
    return this.langService.getLang();
  }

  ngOnInit() {

  }

  public showLang() {
    this.visibleLang = !this.visibleLang;
  }

  public showToolbar() {
    this.visible = !this.visible;
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscription.unsubscribe();
  }
}
