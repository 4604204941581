import { CoinAmount } from './coin_amount';
import { Order } from './order';

export class SalesCenter {

    id : number;
    centerName_AZ : string;
    centerName_EN : string;
    status : boolean;
    centerAddress_AZ : string;
    centerAddress_EN : string;
    cnCoinAmount : CoinAmount[];
    cnOrder : Order[];

}