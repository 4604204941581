import { Component, OnInit, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from '../home.service';
import { HomePage } from 'src/app/models_view/home_page';
import { Coin } from 'src/app/models/coin';
import { CoinDetails } from 'src/app/models_view/coin_details';
import { LanguageService } from 'src/app/language.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { MetalType } from 'src/app/models/metal_type';
import { SalesCenter } from 'src/app/models/sales_center';
import { FilterModel } from 'src/app/models/filter_model';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { UserGuideDialog } from '../dialog-box/user_guide';
import { Page } from 'src/app/models/page';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {/*static: true*/ }) paginator: MatPaginator;
  homeService: HomeService;
  homepage_list: HomePage;
  metal_list: MetalType[];
  sales_center_list: SalesCenter[];
  filter_list: FilterModel = new FilterModel;
  public dataSource: any;
  public pageSize = 9;
  public currentPage = 0;
  public totalSize = 0;
  pageEvent: PageEvent;
  coin_length: number;
  description: string;
  salesCenter: string;
  miniSpinner:boolean;
  @ViewChild('content') content: ElementRef;
  coinId:number|null;
  MetalId:number|null;

  constructor(public dialog: MatDialog, private meta: Meta, private TitleService: Title, private route: ActivatedRoute,
    private _sanitizer: DomSanitizer, public langService: LanguageService, private translate: TranslateService, homeService: HomeService) {
    this.homeService = homeService;
    this.meta.updateTag({ property: 'og:title',content: "YUBİLEY VƏ XATİRƏ PUL NİŞANLARININ ONLAYN SATIŞ SİSTEMİ"});
    this.meta.updateTag({ property: 'og:description',content: "Azərbaycan Respublikasının Mərkəzi Bankı tərəfindən mütəmadi olaraq ölkəmizin tarixinə, mədəniyyətinə, elminə, incəsənətinə, eləcə də idman, görkəmli şəxsiyyətlər, əlamətdar hadisələr və s. mövzulara həsr olunmuş yubiley və xatirə pul nişanları tədavülə buraxılır."});
    this.meta.updateTag({ property: 'og:image:secure_url',content: window.location.origin +  "/" +"assets/images/e-cbar-3.png" });

    this.getHomeIndex();
    this.getMetalTypes();
    this.getSalesCenters();
    translate.setDefaultLang(this.langService.getLang().toLowerCase());

  }
  setTitle() {
    this.TitleService.setTitle(this.homepage_list.currentPage["header_" + this.langService.getLang().toUpperCase()] + ' | E-CBAR.AZ');
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.coinId=+params['id'];
      if(this.coinId){
        this.homeService.getCoinMetalId(this.coinId).subscribe(
          data=>{
            this.MetalId=data;
            this.filter_list.filter_metal_id=this.MetalId;
            if(this.MetalId){
                 this.onFilter(this.MetalId,2,0);}
          }
        )
      }else{
        this.filter_list.filter_metal_id = 0;
      }
    });
    
    this.translate.use(this.langService.getLang().toLowerCase());

  }

  checkfunction() {

    //   grecaptcha.ready(function() {
    //     grecaptcha.execute('6Le8etQUAAAAANCpJlqkrawgb0hr-4h8qA9MVlAz', {action: 'homepage'}).then(function(token) {
    //      console.log(token)
    //      var response = grecaptcha.getResponse();
    //      if(response.lenght==0 ) { 

    //       document.getElementById('verified').innerHTML='<span style="color:red;">You are robot!</span>'
    //      }
    //      else { 
    //        document.getElementById('verified').innerHTML='<span style="color:green;">Success!</span>'     
    //           }
    //     });
    // });
    ;
  }
  set_txt() {
    setTimeout(() => {
      this.content.nativeElement.innerHTML = "<html><body><p>" + this.description + "</p></body></html>";

    }, 1000);
  }

  get_txt(data) {
    return data.replace(/i/g, 'i');
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.content.nativeElement.innerHTML = "<html><body><p>" + this.description + "</p></body></html>";

    // }, 1000);
  }
  getHomeIndex() {
    this.homeService.getHomeIndex().subscribe(
      ((data) => {
        // let content = this.content.nativeElement.innerHTML;
        // console.log(content)
        this.homepage_list = data;
        this.description = data.currentPage["description_" + this.langService.getLang().toUpperCase()];
        this.dataSource = new MatTableDataSource<Coin>(this.homepage_list.coinList);
        this.coin_length = this.homepage_list.coinList.length;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.homepage_list.coinList.length;
        this.iterator();

      }
      ));
  }

  getMetalTypes() {
    this.miniSpinner = true;
    this.homeService.getMetalTypesByCoin().subscribe(
      ((data) => {
        this.metal_list = data;
        this.miniSpinner = false;
      }
      ));
  }

  getSalesCenters() {
    this.homeService.getSalesCenters().subscribe(
      ((data) => {
        this.sales_center_list = this.order_by(data);
      }
      ));
  }

  order_by(filtr: SalesCenter[]) {
    filtr = filtr.sort((a, b) => a["centerName_" + this.langService.getLang().toUpperCase()]
      .localeCompare(b["centerName_" + this.langService.getLang().toUpperCase()]));
    return filtr;
  }

  // getImageBase64(base64String: string) {
  //   return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + base64String);
  // }

  onFilterByPrice(value) {
    if (value == 0) {
      this.homepage_list.coinList = this.homepage_list.coinList.sort((a, b) => b.invPurpose - a.invPurpose);
    }
    if (value == 1) {
      this.homepage_list.coinList = this.homepage_list.coinList.sort((a, b) => a.price - b.price);
    }
    else if (value == 2) {
      this.homepage_list.coinList = this.homepage_list.coinList.sort((a, b) => b.price - a.price);
    }

    this.dataSource = new MatTableDataSource<Coin>(this.homepage_list.coinList);
    this.dataSource.paginator = this.paginator;
    this.iterator();
  }

  onFilter(event, n: number, price) {
    if (n == 1) {
      this.filter_list.filter_name = event;
    }
    else if (n == 2) {
      this.filter_list.filter_metal_id = event;
    }
    else {
      this.filter_list.filter_sales_id = event;
      if (event) {
        this.salesCenter = this.sales_center_list.find(c => c.id == event)['centerName_' + this.langService.getLang().toUpperCase()];
      }
    }

    this.homeService.getHomeIndex().subscribe(
      ((data) => {
        this.homepage_list = data;
        let cList = this.homepage_list.coinList;
        this.homepage_list.coinList = [];
        for (let i = 0; i < cList.length; i++) {
          var flag1 = 1, flag2 = 1, flag3 = 1;
          if (this.filter_list.filter_metal_id > 0) {
            if (cList[i].metal_Type_ID == this.filter_list.filter_metal_id) {
              flag2 = 1;
            }
            else {
              flag2 = 0;
            }
          }
          if (this.filter_list.filter_name) {
            if (cList[i]["coinName_" + this.langService.getLang().toUpperCase()].toString().toUpperCase().includes(this.filter_list.filter_name.toUpperCase())) {
              flag1 = 1;
            }
            else {
              flag1 = 0;
            }
          }
          if (this.filter_list.filter_sales_id > 0 && cList[i].cnCoinAmount.length == 0) {
            flag3 = 0;
          } else if (this.filter_list.filter_sales_id > 0) {
            flag3 = 0;
            for (let j = 0; j < cList[i].cnCoinAmount.length; j++) {
              if (cList[i].cnCoinAmount[j].salesCenterId == this.filter_list.filter_sales_id && cList[i].cnCoinAmount[j].quantity > 0) {
                flag3 = 1;
                break;
              }
            }
          }
          if (flag1 == 1 && flag2 == 1 && flag3 == 1) {
            this.homepage_list.coinList.push(cList[i]);
          }
        }

        this.dataSource = new MatTableDataSource<Coin>(this.homepage_list.coinList);
        this.coin_length = this.homepage_list.coinList.length;
        this.dataSource.paginator = this.paginator;
        this.totalSize = this.homepage_list.coinList.length;
        this.iterator();
        this.onFilterByPrice(price);
      }));
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
    return e;
  }



  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.homepage_list.coinList.slice(start, end);
    this.dataSource = part;
  }
}

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private lang: LanguageService) {
    super();
    if (this.lang.getLang() == 'az') {
      this.firstPageLabel = 'İlk səhifə'
      this.previousPageLabel = 'Əvvəlki səhifə';
      this.nextPageLabel = 'Növbəti səhifə';
      this.lastPageLabel = 'Son səhifə';
    }
  }
}