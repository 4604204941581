import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/base.service';
import { Observable, throwError } from 'rxjs';

import { LoginASANVM } from 'src/app/models/LoginASANVM';
import { AppConfig } from '../app.config';


@Injectable({
  providedIn: 'root',
})

export class AccountService extends BaseService {
  lvm: LoginASANVM;
  myAppUrl: string;
  constructor(private http: HttpClient) {
    super();
    this.clearStorage();
  }
  public getStateId(): Observable<any> {
    // debugger;
    this.myAppUrl = AppConfig.settings?.other.resourceApiURI;
    return this.http.get(`${this.myAppUrl}/User/asanguid`, {responseType: 'text'});
  }
  clearStorage() {
    var hours = AppConfig.settings.other.clearStorageHour; 
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now.toString())
    } else {
      if (now - parseInt(setupTime) > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem('setupTime', now.toString());
      }
    }
  }
  getMyToken(data_lvm) {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.post(this.myAppUrl + "/user/LoginASAN/", data_lvm).
      pipe(map(response => {
        return response
      }));
  }
  getAsanTokeTestMain() {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true
    };
    return this.http.get(this.getAsanURLLoginToken(), httpOptions).
      pipe(map(response => {
        this.lvm = new LoginASANVM;
        this.lvm.generalToken = response['data']['token'];
        this.lvm.errorMessage = response['exception'];

        return this.lvm;
      }), catchError(error => {
        this.lvm = new LoginASANVM;
        this.lvm.errorMessage = error['error']['exception']['code'];
        this.lvm.generalToken = null;
        localStorage.removeItem("coin_token");
        localStorage.removeItem("user_id");
        return throwError(error);
      }));
  }

  getAsanCheck(lvm: LoginASANVM) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': lvm.generalToken
      })
    };
    return this.http.get(this.getAsanURLLoginCheck(), httpOptions).
      pipe(map(response => {
        lvm.checkCode = response['status'];
        return lvm;
      }), catchError(error => {
        localStorage.removeItem("coin_token");
        localStorage.removeItem("user_id");
        return throwError(error);
      }));
  }

  getAsanURLLogin(): string {
    return AppConfig.settings.asan.asanUrlLogin;
  }
  getAsanURLLoginToken(): string {
    return AppConfig.settings.asan.asanUrlLoginToken;
  }

  getAsanURLLoginCheck(): string {
    return AppConfig.settings.asan.asanUrlLoginCheck;
  }
  getAsanURLLogout(): string {
    return AppConfig.settings.asan.asanUrlLogout;
  }

  public loginEasy(params: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.post(`${this.myAppUrl}/User/asan-login`, params);
  }
}
