import { OnInit, Component, ViewEncapsulation } from '@angular/core';
import { HomeService } from '../home.service';
import { CoinDetails } from 'src/app/models_view/coin_details';
import { TranslateService } from '@ngx-translate/core';
import { Coin } from 'src/app/models/coin';
import { Page } from 'src/app/models/page';
import { LanguageService } from 'src/app/language.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CartItem } from 'src/app/models/cart_item';
import { CoinAmount } from 'src/app/models/coin_amount';
import { Ng7DynamicBreadcrumbService } from 'ng7-dynamic-breadcrumb';
import { StorageService } from 'src/app/storage.service';

@Component({

  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['../index/index.component.scss'],
})
export class DetailsComponent implements OnInit {
  title;
  homeService: HomeService;
  coinDetails: CoinDetails;
  id: number;
  cart: CartItem[] = [];
  cartitem: CartItem;
  amounts: CoinAmount[];
  isbuyyable: boolean;
   constructor(public meta:Meta, private TitleService: Title, private storageService: StorageService, private ng7DynamicBreadcrumbService: Ng7DynamicBreadcrumbService,
    private activatedRoute: ActivatedRoute, private router: Router, public langService: LanguageService, private translate: TranslateService, homeService: HomeService, private _sanitizer: DomSanitizer) {
    this.homeService = homeService;
    
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.getDetails();
    });
  }

  ngOnInit() {
    this.translate.use(this.langService.getLang().toLowerCase());

  }


  setTitle(title: string) {
    if (title) {
      this.TitleService.setTitle(title + ' | E-CBAR.AZ');
      const breadcrumb = { Title: this.coinDetails.coin.metalType["typeName_"+this.langService.getLang().toUpperCase()],MetalId: this.coinDetails.coin.metalType.id};
      this.ng7DynamicBreadcrumbService.updateBreadcrumbLabels(breadcrumb);
    }
  }



  getDetails() {
    this.homeService.getCoinDetails(this.id).subscribe(
      ((data: CoinDetails) => {
        this.coinDetails = data;

        let img_meta = window.location.origin + `/assets/coinimages/coin_${this.coinDetails.coin.id}_2.png`;
        this.meta.removeTag('property="og:title"');

        this.meta.updateTag({ property: 'og:title',content: this.coinDetails.coin["coinName_"+this.langService.getLang().toUpperCase()]});
        this.meta.updateTag({ property: 'og:description',content: this.coinDetails.coin["coinName_"+this.langService.getLang().toUpperCase()] + " - " + this.coinDetails.coin.price + "₼"});
        this.meta.updateTag({ property: 'og:image:secure_url',content: img_meta });
    
      }
      ));
  }



  // getImageBase64(base64String: string) {
  //   return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + base64String);
  // }
  get_txt(data) {
    return data.replace(/Ə/g, 'ə');
  }

  buy(id: number, price:number) {

    if(this.homeService.hasExceedLimit(price)) return;

    if (this.storageService.getJsonValue("cart") == null) {
      this.homeService.buy(id).subscribe(
        ((data: CartItem) => {
          this.cart.push(data);
          // localStorage.setItem('cart', JSON.stringify(this.cart));
          this.storageService.setJsonValue("cart", this.cart);
          this.router.navigate(["/cart/index"]);
        }
        ));
    }
    else {
      // this.cart = JSON.parse(localStorage.getItem('cart'));
      this.cart = this.storageService.getJsonValue("cart");
      var index = this.isExist(id);
      if (index != -1) {
        this.isBuyyable(id, index);

      }
      else {
        this.homeService.buy(id).subscribe(
          ((data: CartItem) => {
            this.cart.push(data);
            // localStorage.setItem('cart', JSON.stringify(this.cart));
            this.storageService.setJsonValue("cart", this.cart);
            this.router.navigate(["/cart/index"]);
          }
          ));
      }

    }


  }

  isExist(id: number) {
    // this.cart = JSON.parse(localStorage.getItem('cart'));
    this.cart = this.storageService.getJsonValue("cart");
    for (var i = 0; i < this.cart.length; i++) {
      if (this.cart[i].coin.id == id) {
        return i;
      }
    }
    return -1;
  }

  isBuyyable(id: number, index: number) {
    // this.cart = JSON.parse(localStorage.getItem('cart'));
    this.cart = this.storageService.getJsonValue("cart");
    this.cartitem = null;
    for (var i = 0; i < this.cart.length; i++) {
      if (this.cart[i].coin.id == id) {
        this.cartitem = this.cart[i];
        break;
      }
    }

    // var coinlimit = this.cartitem.coin.dailyLimit;
    this.homeService.getCoinAmounts(id).subscribe(
      ((data: CoinAmount[]) => {
        this.amounts = data;
        let pList = this.amounts;
        pList.forEach(amount => {
          if (this.cartitem.maxCount < amount.quantity) {
            this.cartitem.maxCount = amount.quantity;
            // if (this.cartitem.maxCount > this.cartitem.coin.dailyLimit) {
            //   this.cartitem.maxCount = this.cartitem.coin.dailyLimit;
            // } 
          }
        });

        if (this.cartitem.maxCount == this.cartitem.quantity) {
          this.isbuyyable = false;
        }
        else {
          this.isbuyyable = true;
          this.cart[index].quantity++;
        }
        // localStorage.setItem('cart', JSON.stringify(this.cart));
        this.storageService.setJsonValue("cart", this.cart);
        this.router.navigate(["/cart/index"]);
      }
      ));



  }


}