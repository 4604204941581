import { OnInit, Component } from '@angular/core';
import { HomeService } from '../home.service';
import { TranslateService } from '@ngx-translate/core';
import { Person } from 'src/app/models/person';
import { Order } from 'src/app/models/order';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['../index/index.component.scss']
  })

  export class customerComponent implements OnInit {
    indexService: HomeService;

    
constructor(private translate: TranslateService,private meta: Meta, indexService: HomeService ) {
      this.indexService = indexService;
      this.meta.updateTag({ property: 'og:title',content: "YUBİLEY VƏ XATİRƏ PUL NİŞANLARININ ONLAYN SATIŞ SİSTEMİ"});
      this.meta.updateTag({ property: 'og:description',content: "Azərbaycan Respublikasının Mərkəzi Bankı tərəfindən mütəmadi olaraq ölkəmizin tarixinə, mədəniyyətinə, elminə, incəsənətinə, eləcə də idman, görkəmli şəxsiyyətlər, əlamətdar hadisələr və s. mövzulara həsr olunmuş yubiley və xatirə pul nişanları tədavülə buraxılır."});
      this.meta.updateTag({ property: 'og:image:secure_url',content: window.location.origin +  "/" +"assets/images/e-cbar-3.png" });
  
      // this.ckform = this.formBuilder.group({
      //   id: 0,
      //   lastName: '',
      //   firstName: '',
      //   middleName: '',
      //   pin: '',
      //   phone: '',
      //   email: '',
      //   serialNumber: '',
      //   image_Person: '',
      //   cnOrder:Order
      // });
    }
     
    ngOnInit() {
     
    }
  }