import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/base.service';
import { HomePage } from 'src/app/models_view/home_page';
import { Observable } from 'rxjs';

import { CoinDetails } from 'src/app/models_view/coin_details';
import { LoginASANVM } from 'src/app/models/LoginASANVM';
import { MetalType } from 'src/app/models/metal_type';
import { SalesCenter } from 'src/app/models/sales_center';
import { CartItem } from '../models/cart_item';
import { CoinAmount } from '../models/coin_amount';
import { CoinImage } from '../models/coin_image';
import { OrderTime } from '../models/order_time';
import { Person } from '../models/person';
import { Order } from '../models/order';
import { OrderCoinView } from '../models_view/order_coin_view';
import { OrderSaleView } from '../models_view/order_sale_view';
import { Page } from '../models/page';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from '../app.config';
import { StorageService } from '../storage.service';
import { Meta } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root',
})

export class HomeService extends BaseService {
  lvm: LoginASANVM;
  myAppUrl: string;
  my_user_id: any;
  exceedLimit: number;
  constructor(private http: HttpClient,private meta: Meta,  private storageService: StorageService) {
    super();

    this.exceedLimit = AppConfig.settings.gpp.exceedLimit;
    const helper = new JwtHelperService();
    if (localStorage.getItem("coin_token")) {
      let d = helper.decodeToken(localStorage.getItem("coin_token"));
      this.my_user_id = d["UserID"];
    }

  }

  get_userId_fromToken() {
    const helper = new JwtHelperService();
    if (localStorage.getItem("coin_token")) {
      let d = helper.decodeToken(localStorage.getItem("coin_token"));
      return d["UserID"];
    }
  }
  getMyToken(data_lvm) {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.post(this.myAppUrl + "/user/LoginASAN/", data_lvm).
      pipe(map(response => {

        return response
      }));
  }
  getAsanTokeTestMain() {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true
    };
    return this.http.get(this.getAsanURLLoginToken(), httpOptions).
      pipe(map(response => {
        this.lvm = new LoginASANVM;
        this.lvm.generalToken = response['data']['token'];
        this.lvm.errorMessage = response['exception'];


        return this.lvm;
      }), catchError(error => {
        this.lvm = new LoginASANVM;
        this.lvm.errorMessage = error['error']['exception']['code'];
        this.lvm.generalToken = null;
        return error;
      }));
  }

  getCoinDetails(id: number): Observable<CoinDetails> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/coin/details/" + id;
    var x = this.http.get<CoinDetails>(url).pipe(map(
      response => {
        return response;
      }));
    return x;
  }

  getCoinMetalId(id: number): Observable<number> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/coin/coinMetalId/" + id;
    var x = this.http.get<number>(url).pipe(map(
      response => {
        return response;
      }));
    return x;
  }

  getUserGuide(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/home/user-guide";
    return this.http.get<any>(url);
  }
  getInvestSaleGuide(): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/home/invest-sale-guide";
    return this.http.get<any>(url);
  }

  getHomeIndex(): Observable<HomePage> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/home/index";
    return this.http.get<HomePage>(url);
  }

  getMetalTypes(): Observable<MetalType[]> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/home/metal_types";
    return this.http.get<MetalType[]>(url);
  }
  getMetalTypesByCoin(): Observable<MetalType[]> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/home/metal_types_by_coin";
    return this.http.get<MetalType[]>(url);
  }
  
  getSalesCenters(): Observable<SalesCenter[]> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/home/sales_centers";
    return this.http.get<SalesCenter[]>(url);
  }

  getTermsAndConditions(): Observable<Page> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/home/terms_conditions";
    return this.http.get<Page>(url);
  }

  getS_Center(id: any): Observable<SalesCenter> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/home/sales_center/" + id;
    return this.http.get<SalesCenter>(url);
  }


  get_person_picture() {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.get(this.myAppUrl + "/userProfile/picture/" + this.get_userId_fromToken(), {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token")
      }), responseType: 'text'
    }).
      pipe(map(response => {
        return response
      }));
  }

  payment_check_date() {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.get(this.myAppUrl + "/order/payment_check_date/", {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }), responseType: 'text'
    }).
      pipe(map(response => {
        return response
      }));
  }


  getO_Time(id: any): Observable<OrderTime> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/order/order_time/" + id;
    return this.http.get<OrderTime>(url);
  }

  getOrderTimeById(id: number): Observable<OrderTime[]> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/order/getOrderTimeById/" + id;
    var x = this.http.get<OrderTime[]>(url).pipe(map(
      response => {
        return response;
      }));
    return x;
  }

  getOrderTimeByDate(id: number, sales_center_id: number): Observable<OrderTime[]> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/order/getOrderTimeByDate/" + id + "/" + sales_center_id;
    var x = this.http.get<OrderTime[]>(url).pipe(map(
      response => {
        return response;
      }));
    return x;
  }

  getOrderModel(): Observable<Person> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token")
      })
    };
    let url = this.myAppUrl + "/order/checkout/" + this.get_userId_fromToken();
    var x = this.http.get<Person>(url, httpOptions).pipe(map(
      response => {
        return response;
      }));
    return x;
  }

  getOrderError(model: any) {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token")
      })
    };
    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "az");
    }

    return this.http.post(this.myAppUrl + "/order/check_error/" + localStorage.getItem("lang"), model, httpOptions).
      pipe(map(response => {
        return response
      }));
  }

  getOrderError_NO(model: any) {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token")
      })
    };
    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "az");
    }

    return this.http.post(this.myAppUrl + "/order/check_error_no/" + localStorage.getItem("lang"), model, httpOptions).
      pipe(map(response => {
        return response
      }));
  }

  getOrderComplete(model: any): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.post(this.myAppUrl + "/order/orderCompleteModel/" + this.get_userId_fromToken(), model, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token"),
      })
    }).
      pipe(map(response => {
        return response
      }));
  }

  gpp_initiatepayment(model): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.post(this.myAppUrl + "/order/gpp_initiatepayment/", model, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token"),
      })
    }).
      pipe(map(response => {
        return response
      }));
  }

  gpp_user_payer(user_id): Observable<any> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/order/gpp_user_payer/" + user_id;
    return this.http.get<any>(url);
  }

  getPaymentResult(doc_no: any): Observable<OrderSaleView> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token")
      })
    };
    return this.http.get<OrderSaleView>(this.myAppUrl + "/order/paymentResult/" + doc_no, httpOptions).
      pipe(map(response => {
        return response
      }));
  }

  setPayStatus(docno: any) {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.get(this.myAppUrl + "/order/order_set_pay/" + docno, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token"),
      }), responseType: 'text'
    }).
      pipe(map(response => {
        return response
      }));
  }


  sendEmail(docno: any) {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "az");
    }
    return this.http.get(this.myAppUrl + "/order/send_email/" + docno + "/" + localStorage.getItem("lang"), {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token"),
      }), responseType: 'text'
    }).
      pipe(map(response => {
        return response
      }));
  }

  getOrderCheck(id: number) {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    return this.http.get(this.myAppUrl + "/userProfile/getordercheck/" + id, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token"),
      }), responseType: 'text'
    }).
      pipe(map(response => {
        return response
      }));
  }


  buy(id: number): Observable<CartItem> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/cart/buy/" + id;
    var x = this.http.get<CartItem>(url).pipe(map(
      response => {
        return response;
      }));
    return x;
  }
  getCoinAmounts(id: number): Observable<CoinAmount[]> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/cart/coin_amount/" + id;
    return this.http.get<CoinAmount[]>(url);
  }

  getCoinImage(id: number): Observable<CoinImage> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    let url = this.myAppUrl + "/cart/coin_image/" + id;
    var x = this.http.get<CoinImage>(url).pipe(map(
      response => {
        return response;
      }));
    return x;
  }

  getMyOrders(): Observable<Order[]> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token")
      })
    };

    let url = this.myAppUrl + "/userProfile/getMyOrders/" + this.get_userId_fromToken();
    return this.http.get<Order[]>(url, httpOptions);

  }

  getOrderCoins(id: number): Observable<OrderCoinView[]> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token")
      })
    };

    let url = this.myAppUrl + "/userProfile/getordercoins/" + id;
    return this.http.get<OrderCoinView[]>(url, httpOptions);

  }

  getOrderById(id: number): Observable<Order> {
    this.myAppUrl = AppConfig.settings.other.resourceApiURI;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem("coin_token")
      })
    };
    let url = this.myAppUrl + "/userProfile/getorderbyid/" + id;
    return this.http.get<Order>(url, httpOptions);
  }

  // getTokenOrderComplete(model: OrderView): Observable<any> {
  //   this.myAppUrl = AppConfig.settings.other.resourceApiURI;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       'Authorization': "Bearer " + localStorage.getItem("coin_token")
  //     })
  //   };
  //   console.log(model);

  //   let url = this.myAppUrl + "/userProfile/tokenOrderComplete/";
  //   return this.http.post<OrderView>(url, model, httpOptions);
  // }

  getAsanURLLoginToken(): string {
    return AppConfig.settings.asan.asanUrlLoginToken;
  }

  hasExceedLimit(price: number): boolean {
    let totalPrice: number = this.storageService.getJsonValue("total_price");
    if (totalPrice + price >= this.exceedLimit) {
      return true;
    }
    else {
      return false;
    }
  }

}
