import { LanguageService } from 'src/app/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Inject, Component } from '@angular/core';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import { Page } from 'src/app/models/page';
import { HomeService } from 'src/app/home/home.service';
import { GPPPayer } from 'src/app/models/gpp_payer';
import { GPPClientAppDetails } from 'src/app/models/gpp_clientAppDetails';
import { GPPPayment } from 'src/app/models/gpp_payment';
import { GPPGppDetails } from 'src/app/models/gpp_gppDetails';
import { AppConfig } from 'src/app/app.config';
import { GPPInvoice } from 'src/app/models/gpp_invoice';
import { GPPInvoicePayload } from 'src/app/models/gpp_invoice_payload';

@Component({
  selector: 'gpp_payment-dialog',
  templateUrl: './gpp_payment.html',
  styleUrls: ['.//gpp_payment.scss']

})
export class GppPaymentDialog {
  my_order: any;
  code: number;
  isPay: boolean;
  codeValidate: boolean = false;
  card_error: boolean = false;
  showSpinner: boolean;
  constructor(public homeService: HomeService,
    public langService: LanguageService, private translate: TranslateService,
    public dialogRef: MatDialogRef<GppPaymentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _sanitizer: DomSanitizer) {
    this.my_order = data.data;
  }


  getTranslatedText(key: string) {
    return this.translate.get(key);
  }



  codeLength(event) {
    let code = event.target.value;
    if (code.length > 6 || code.length < 6) {
      this.codeValidate = false;
    }
    else {
      this.codeValidate = true;
    }
    this.card_error = false;
  }

  validateCode(event) {
    let code = event.target.value;
    if (code.length >= 6) {
      this.code = code.substr(0, 5);
    }
  }


  goto_gpp() {
    this.showSpinner = true;
    console.log(this.code + " " + this.codeValidate)
    this.isPay = true;
    if (!this.code) {
      this.codeValidate = false;
      return;
    }
    if (!this.codeValidate) {
      return;
    }
    this.homeService.getPaymentResult(this.my_order.doc_No).subscribe(
      ((data) => {
        console.log(data)
        if (data && data.payment_Amount != data.amount_To_Pay) {
          let payer = new GPPPayer;
          payer.payerId = this.my_order.pin.toString();
          payer.isResident = true;
          payer.isLegal = false;

          let clientAppDetails = new GPPClientAppDetails;
          clientAppDetails.appName = "coin";

          let payment = new GPPPayment;
          payment.cardFirstSixDigits = this.code.toString();

          let gppDetails = new GPPGppDetails;
          if (localStorage.getItem("lang").toLowerCase() == "az") {
            clientAppDetails.locale = "az_AZ";
          } else {
            clientAppDetails.locale = "en_US";
          }
          clientAppDetails.redirectUrl = AppConfig.settings.urls.coinReturnUrl + this.my_order.doc_No;


          let invoice = new GPPInvoice;
          invoice.invoiceDate = this.my_order.createDate.toString().substr(0, 10);
          invoice.invoiceNumber = this.my_order.doc_No;
          gppDetails.serviceCode = AppConfig.settings.gpp.serviceCode;

          let payload = new GPPInvoicePayload;
          payload.payer = payer;
          payload.invoice = invoice;
          payload.payment = payment;
          payload.clientAppDetails = clientAppDetails;
          payload.gppDetails = gppDetails;



          console.log(payload);
          this.homeService.gpp_initiatepayment(payload).subscribe(
            ((data) => {
              console.log(data);
              if (data != null && data.redirectUrl != null && data.redirectUrl) {
                this.card_error = false;
                window.location.href = data.redirectUrl;
              } else {
                console.log(data.message)
                console.log(data.transactionId)
                this.card_error = true;
              }
              this.showSpinner = false;
            }));


        }
      }));
  }


}