import { OnInit, Component } from '@angular/core';
import { HomeService } from '../../home.service';
import { CoinDetails } from 'src/app/models_view/coin_details';
import { TranslateService } from '@ngx-translate/core';
import { Coin } from 'src/app/models/coin';
import { Page } from 'src/app/models/page';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-order',
    templateUrl: './orderPdf.component.html',
    styleUrls: ['../../index/index.component.scss']
  })

  export class orderPdfComponent implements OnInit {
    homeService: HomeService;
constructor(private TitleService :Title,private translate: TranslateService,homeService: HomeService) {
      this.homeService = homeService;
    
       }
    
      ngOnInit() {
     
      }
  }